.newPostContent {
  position: absolute;
  /* inset: 18vh; */
  top: 10%;
  left: 35%;
  width: 35%;
  height: 70%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 20px;
  outline: none;
  padding: 0px;
}

.newPostContentMobile {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 90%;
  height: 70%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 20px;
  outline: none;
  padding: 0px;
}

.modalTopBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  padding-left: 1%;
  align-items: center;
  background-color: #B171B3;
  color: #FFFFFF;
  font: normal normal bold 16px/20px Comfortaa;
}

.addThemeIcon {
  width: 25px;
  height: 40%;
  margin: 10px;
}

.modalTitle {
  width: 100%;
  padding-left: 1%;
  text-align: left;

}

.modalButton {
  display: flex;
  height: 10%;
  background-color: #EBB146;
  border-radius: 33px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font: normal normal bold 18px/28px Comfortaa;
  font-size: 80%;
  min-width: 130px;
  letter-spacing: 0px;
  color: #FFFFFF;
  cursor: pointer;
}

.disabled {
  background-color: #e4c487c1;
  color: #ffffffea;
  cursor: not-allowed;
}

.modalBody {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}

.feiaImage {
  margin: auto;
  background-image: url(../../assets/feia.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 25%;
}

.close {
  background-image: url(../../assets/x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10%;
  height: 35%;
  min-width: 10px;
  min-height: 15px;
  align-self: center;
  justify-self: flex-end;
  margin: 10px;
  cursor: pointer;
}