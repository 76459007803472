.container-section-desktop {
  padding-top: 10vw;
}

.container-section-mobile {
  margin-top: 10vw;
}

.container-section-wrapper {
  display: flex;
  align-items: center;
}

.container-section-wrapper-desktop {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-section-wrapper-mobile {
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
  padding-bottom: 2vw;
}

.container-left {
  width: 50%;
}

.container-left-mobile {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3vw;
  padding-top: 5vw;
}

.container-right-desktop {
  width: 50%;
  z-index: 99;
}

.container-right-title {
  text-transform: uppercase;
}

.container-right-title-desktop {
  margin-top: 2vw;
  text-align: left;
  color: #666666;
  font: normal normal normal 3.3vw/3vw MyFontPHCond;
  width: 55%;
  margin-bottom: 1vw;
}

.container-right-title-mobile {
  margin: 0 auto;
  text-align: center;
  width: 90%;
  color: #707070;
  font: normal normal normal 10.7vw MyFontPHCond;
  line-height: 1;
  margin-top: 20vw;
  margin-bottom: 2vw;
}

.container-right-content-desktop {
  text-align: left;
  width: 60%;
  font: normal normal 0.88vw Comfortaa;
  color: #666;
}

.container-right-content-desktop p {
  line-height: 1.9;
  margin-bottom: 0.2vw;
}

.container-right-content-mobile {
  margin: 0 auto;
  text-align: left;
  width: 75%;
  font: normal normal 2.9vw Comfortaa;
  color: #707070;
  line-height: 1.9;
  margin-top: 5vw;
}

.container-right-content-wrapper-desktop {
  display: flex;
  align-items: flex-start;
}

.container-right-content-wrapper-mobile {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

.container-right-image-dot-desktop {
  margin-top: 0.6vw;
  margin-right: 0.7vw;
  width: 0.4vw;
  height: 0.4vw;
}

.container-right-image-dot-mobile {
  margin: 0 auto;
  width: 3%;
  margin-top: 1vw;
  margin-bottom: 3vw;
}

.container-right-content-wrapper-mobile:last-child
  .container-right-image-dot-mobile {
  display: none;
}

.container-right-content-wrapper-desktop p {
  color: #707070;
  font: normal normal 300 0.95vw/1.7vw Comfortaa;
}

.container-right-content-wrapper-mobile p {
  color: #666666;
  font: normal normal 300 3.2vw/5.7vw Comfortaa;
}

.install-now-button {
  background-color: #ffc701;
  text-transform: uppercase;
  color: white;
}

.install-now-button-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 2.7vw;
  font: normal normal 0.78vw Comfortaa;
  border-radius: 4vw;
  transition: 0.1s ease-in-out;
  z-index: 99;
  margin-top: 1.5vw;
  cursor: pointer;
}

.install-now-button-desktop:hover {
  background-color: #b171b3;
}

.install-now-button-mobile {
  margin: 0 auto;
  margin-top: 4vw;
  width: 35vw;
  border-radius: 30px;
  font: normal normal 2.4vw Comfortaa;
  padding: 3.3vw 3vw 3.3vw 3vw;
}

.install-now-button-mobile:active {
  background-color: #b171b3;
}

.install-now-button-mobile:-webkit-any-link {
  cursor: none;
}

.future-mother-clouds-white-desktop {
  margin-top: 5vw;
  width: 118%;
}

.future-mother-clouds-white-mobile {
  width: 105%;
  margin-bottom: -10vw;
}

.container-right-content-desktop li {
  color: #707070;
  text-align: left;
  line-height: 2;
  font: normal normal 0.94vw/1.75vw Comfortaa;
  list-style-type: none;
}
.container-right-content-desktop li p::before {
  content: "\2022";
  color: #f18868;
  font-weight: bold;
  margin-right: 0.3vw;
}

.container-right-content-mobile {
  margin-top: 15vw;
}

.container-right-content-mobile li {
  margin: 0 auto;
  text-align: center;
  font: normal normal bold 3.3vw/4vw Comfortaa;
  color: #666666;
  line-height: 1.6;
  list-style-type: none;
}

.container-right-content-mobile li p::after {
  content: "\2022";
  color: #f18868;
  font-weight: bold;
  display: block;
  font-size: 5vw;
}

.container-right-content-mobile li p:last-of-type::after {
  display: none;
}
