.contacts-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contacts-header-mobile {
  margin-top: 20vw;
}

.contacts-header-title {
  margin: 0 auto;
  color: #ebb146;
  text-transform: uppercase;
}
.contacts-header-title-mobile {
  font: normal normal normal 15vw/10.78vw MyFontPHCond;
  letter-spacing: 0px;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
}

.contacts-header-title-desktop {
  margin-top: 10.5vw;
  font: normal normal normal 7.2vw/8.58vw MyFontPHCond;
  letter-spacing: 0px;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
}

.contacts-header-title-desktop::after {
  color: #ebb146;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 0.9vw #fff;
  left: 0vw;
  z-index: -3;
}

.contacts-header-title-mobile::after {
  color: #ebb146;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 1.2vw #fff;
  left: 0vw;
  z-index: -3;
}

.contacts-header-image-container {
  width: 115%;
  margin-left: -2vw;
  margin-top: -18vw;
  transform: rotate(-3deg);
}

.contacts-header-image-container img {
  width: 100%;
  height: auto;
}

.contacts-artwork-desktop {
  margin: 0 auto;
  width: 30%;
  position: absolute;
  top: -3vw;
  left: 1vw;
}

.contacts-artwork-mobile {
  margin: 0 auto;
  width: 60%;
  position: absolute;
  top: 0vw;
  left: -20vw;
}

.contacts-artwork img {
  width: 100%;
  height: auto;
}
