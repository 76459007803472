.arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prev,
.next {
  cursor: pointer;
  z-index: 99999999;
  position: absolute;
  top: 48.5%;
}
.prev-mobile {
  margin-left: -7vw;
}
.next-mobile {
  margin-right: -7vw;
}
.prev-mobile,
.next-mobile {
  z-index: 100;
  position: absolute;
  top: 45.5%;
  cursor: none;
}

.next {
  right: 0;
}

.next-mobile {
  right: 1vw;
}
.arrows-img-next-desktop {
  width: 2vw;
}

.arrows-img-next-mobile {
  width: 8.5vw;
  margin-right: -4vw;
}

.arrows-img-prev-desktop {
  width: 2vw;
}

.arrows-img-prev-mobile {
  width: 8.5vw;
  margin-left: -4vw;
}
