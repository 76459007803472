.rating-container-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  height: 10.5vw;
  width: 65%;
  margin-top: -2vw;
}

.rating-container-desktop .slider {
  /* position: inherit; */
}

.rating-container-mobile {
  margin: 0 auto;
  padding-top: 7vw;
  height: auto;
  background-color: white;
}

.rating-container .slider {
  z-index: 80;
}
.sliperslide-wrapper {
  margin-top: -0.2vw;
}

.rating-text-desktop {
  font: normal normal bold 0.74vw/1.35vw Comfortaa;
  color: #666666;
  margin-top: 1vw;
  /* z-index: 9999; */
}

.rating-text-desktop:hover {
  cursor: grab;
}

.rating-text-mobile p {
  margin: 0 auto;
  font: normal normal bold 3.2vw/6vw Comfortaa;
  color: #333;
}

.rating-author-desktop {
  font-size: 0.8vw;
  font-family: "Comfortaa";
  color: #797979;
  margin-top: 1vw;
}

.rating-author-desktop:hover {
  cursor: default;
}

.rating-author-mobile {
  font-size: 2.9vw;
  font-family: "Comfortaa";
  color: #797979;
  margin-top: 3vw;
  margin-bottom: 4vw;
}

.rating {
  width: 100%;
  position: relative;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.stars {
  margin-bottom: 1vh;
  height: 1.29vw;
}

.stars-mobile {
  margin: 0 auto;
  margin-bottom: 2vh;
  width: 20vw;
  height: auto;
}

.slider-control-centerleft,
.slider-control-centerright {
  display: none;
}

.paging-item {
  margin-right: 0.4vw;
}

.paging-item button svg {
  display: none !important;
}

.paging-item button {
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50% !important;
  background-color: #f6efea !important;
  border: 0.15vw solid #f6efea !important;
  opacity: 1 !important;
}

.paging-item.active button {
  border: 0.15vw solid #b171b3 !important;
  background-color: transparent !important;
}

.rating-container-mobile .paging-item button {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 1vw;
}

.rating-container-desktop .slider-slide {
  width: 100% !important;
}
#scrollContainer
  > div.pregnancy-rating-container.pregnancy-rating-container-desktop
  > div
  > div
  > div.slider-control-bottomcenter
  > ul {
  top: 0 !important;
}

.rating-container-mobile .slider .slider-control-bottomcenter ul {
  top: 1vw !important;
}

.pregnancy-main-mobile
  .rating-container-mobile
  .slider
  .slider-control-bottomcenter
  ul {
  top: 10vw !important;
}

.pregnancy-main-desktop .rating-component-desktop {
  background-color: white;
  padding-top: 5vw;
  padding-bottom: 5vw;
}
