.react-responsive-modal-modal {
  background-color: transparent !important;
  box-shadow: none !important;
}

.react-responsive-modal-modal .loader ~ .react-responsive-modal-closeButton{
display: none
}

.pregnancy-modal-video-desktop {
  position: relative;
  width: 30vw;
  height: 37vw;
  top: 1vw;
  margin-bottom: 2vw;
}

.pregnancy-modal-video-mobile {
  width: 80vw;
  height: 130vw;
}

.modal-phone-container-desktop {
  position: relative;
}
.modal-phone-container-mobile {
  position: relative;
}

.modal-phone-container img {
  position: absolute;
  z-index: 999;
}

.modal-phone-container-mobile img {
  width: 88%;
  top: -4vw;
  left: 5vw;
}

.modal-phone-container-desktop img {
  width: 66%;
  left: 5vw;
  padding-bottom: 3vw;
}

.react-responsive-modal-closeButton {
  z-index: 9999;
}

.new-loader {
  width: 10vw;
  height: 10vw;
  padding: 2vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#C190C3 94%,#0000) top/12px 12px no-repeat,
    conic-gradient(#0000 30%,#C190C3);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 12px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

.new-loader-mobile{
  width: 40vw;
  height: 40vw;
  padding: 3vw;
}