.blog-category-title-mobile:-webkit-any-link {
  cursor: none;
}
.blog-item-p {
  color: hsla(198, 100%, 45%, 1);
  display: inline-block;
  width: auto;
}
.blog-item-p-descktop {
  font: normal normal 300 0.63vw Comfortaa;
}
.blog-item-p-mobile {
  margin-top: 4vw;
  margin-bottom: 2.6vw;
  font: normal normal 300 3.2vw Comfortaa;
}
