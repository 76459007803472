.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ReactModalPortal {
  position: absolute;
  z-index: 998;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background-color: transparent !important; */
}

.modalTopBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  padding-left: 1%;
  align-items: center;
  background-color: #B171B3;
  color: #FFFFFF;
  font: normal normal bold 16px/20px Comfortaa;

}

.addThemeIcon {
  width: 25px;
  height: 40%;
  margin: 10px;
}

.modalTitle {
  width: 100%;
  padding-left: 1%;
  text-align: left; 
   
}

.modalButton {
  display: flex;

  height: 10%;
  background-color: #EBB146;
  border-radius: 33px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font: normal normal bold 18px/28px Comfortaa;
  font-size: 80%;
  min-width: 130px;
  letter-spacing: 0px;
  color: #FFFFFF;
  cursor: pointer;
}

.modalBody {
  width: 70%;
  height: 90%;
  margin: auto;
}

.close {
  background-image: url(../../assets/x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10%;
  min-height: 15px;
  height: 35%;
  align-self: center;
  justify-self: flex-end;
  margin: 10px;
  cursor: pointer;
}

.input {
  text-align: left;
  font: normal normal bold 14px/15px Comfortaa;
  letter-spacing: 0px;
  opacity: 1;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #B171B3;
  color: #B171B3;
  ;
}

.input::-webkit-input-placeholder {
  color: #B171B3;
  font: normal normal bold 18px/22px Comfortaa;
  font-size: 100%;
}

.input::-moz-placeholder {
  color: #B171B3;
  font: normal normal bold 18px/22px Comfortaa;
  font-size: 100%;
}

.input:-ms-input-placeholder {
  color: #B171B3;
  font: normal normal bold 18px/22px Comfortaa;
  font-size: 100%;
}

.input:-moz-placeholder {
  color: #B171B3;
  font: normal normal bold 18px/22px Comfortaa;
  font-size: 100%;
}