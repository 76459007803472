.contacts-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contacts-form-mobile {
  width: 90%;
  margin: 0 auto;
  z-index: 9999;
}
.contacts-form-desktop {
  width: 50%;
}

.contacts-form-container {
  display: flex;
  flex-direction: column;
}

.contacts-form-container-mobile {
  width: 90%;
  margin: 0 auto;
  gap: 3.5vw;
}

.contacts-form-container-desktop {
  width: 50%;
  gap: 0.5vw;
}

.contacts-form-title {
  color: #666666;
  text-transform: uppercase;
}
.contacts-form-title-mobile {
  font: normal normal normal 10.125vw MyFontPHCond;
  text-transform: uppercase;
  text-align: center;
  margin: 6vw 0;
}

.contacts-form-title-desktop {
  color: #666666;
  font: normal normal normal 3.125vw MyFontPHCond;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 1vw;
}

.contacts-form-single-input {
  background-color: #f7f7f7;
  color: #666666;
  border: 0;
}

.contacts-form-single-input-mobile {
  background-color: #f7f7f7;
  color: #666666;
  font: normal normal 300 2.73vw Comfortaa;
  border: 0;
  border-radius: 5vw;
  padding: 3.5vw 0;
  padding-left: 3vw;
}

.contacts-form-single-input-desktop {
  background-color: #f7f7f7;
  color: #666666;
  font: normal normal 300 0.73vw Comfortaa;
  border: 0;
  border-radius: 1.5vw;
  padding: 1vw 0;
  padding-left: 1vw;
  line-height: 1.3vw;
}

.contacts-form-single-input-mobile:first-child {
  line-height: 3.5vw;
  padding: 4vw 0;
  padding-left: 4.5vw;
  margin-bottom: 5vw;
}

.contacts-form-single-input-descktop:first-child {
  line-height: 1.3vw;
  padding: 1.8vw 0;
  line-height: 1.3vw;
}

.contacts-form-container-desktop textarea {
  min-height: 4vw;
  min-width: 20vw;
  max-width: 30vw;
}

.contacs-form-submit {
  background-color: #ebb146;
  color: white;
  border: 0;
  text-transform: uppercase;
}

.contacs-form-submit-mobile {
  font: normal normal bold 3.94vw Comfortaa;
  border-radius: 5vw;
  padding: 2.5vw 0;
  margin-top: 1.5vw;
}

.contacs-form-submit-desktop {
  font: normal normal bold 0.94vw Comfortaa;
  border-radius: 2vw;
  padding: 1vw 0;
  margin-top: 1.5vw;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.contacs-form-submit:hover {
  background-color: #b171b3ed;
}

.contacts-error-message {
  color: #e75656;
  text-align: left;
}

.contacts-error-message-mobile {
  font-size: 3vw;
  margin-left: 3vw;
}
.contacts-error-message-desktop {
  font-size: 0.6vw;
  margin-left: 1vw;
}

.contacts-message-success-desktop {
  color: #71945f;
  font-weight: bold;
  font-size: 0.8vw;
}

.contacts-message-success-mobile {
  color: #71945f;
  font-weight: bold;
  font-size: 3.3vw;
}
