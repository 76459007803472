.social-media-icons-desktop {
  width: 100%;
  height: 0.65vw;
  margin-top: 0.3vw;
}

.social-media-icons-mobile {
  width: 4vw;
  height: 4vw;
}
