.error-content-wrapper {
  display: flex;
}

.error-content-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f8f4f0;
}

.error-artwork-desktop {
  position: absolute;
  width: 27%;
  transform: rotate(-30deg);
  top: 7vw;
  margin-left: -3vw;
}

.error-artwork-mobile {
  position: absolute;
  width: 40%;
  transform: rotate(-30deg);
  top: 10vw;
}

.error-artwork img {
  width: 100%;
  height: auto;
}

.error-content-wrapper-title {
  margin: 0 auto;
}

.error-content-wrapper-title-desktop {
  font: normal normal normal 34.3vw/41.25vw MyFontPHCond;
  color: #9097cb;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
}

.error-content-wrapper-title-mobile {
  font: normal normal normal 50.3vw/45.25vw MyFontPHCond;
  color: #9097cb;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
  margin-top: 15vw;
}

.error-content-wrapper-title::after {
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 3.8vw #fff;
  left: 0vw;
  z-index: -3;
}

.error-coud-content-desktop {
  background-color: #fff;
  width: 100%;
  margin-top: -10vw;
}

.error-coud-content-mobile {
  background-color: #fff;
  width: 100%;
  margin-top: -12vw;
}

.error-cloud-image-container {
  width: 115%;
  margin-left: -3vw;
  margin-top: -24vw;
}
.error-cloud-image {
  width: 100%;
  height: auto;
}

.error-coud-content-title-desktop {
  font: normal normal normal 4.69vw/5.68vw MyFontPHCond;
  text-transform: uppercase;
  color: #666666;
  margin-bottom: 1vw;
}

.error-coud-content-title-mobile {
  font: normal normal normal 8.69vw/5.68vw MyFontPHCond;
  text-transform: uppercase;
  color: #666666;
  margin-bottom: 4vw;
  margin-top: 10vw;
}

.error-coud-content-title span {
  color: #ebb146;
}

.error-cloud-content-go-back-desktop {
  color: #00a0e3;
  font: normal normal bold 1.05vw/1.2vw Comfortaa;
  border-bottom: 1px solid #00a0e3;
}

.error-cloud-content-go-back-mobile {
  color: #00a0e3;
  font: normal normal bold 3vw/1.2vw Comfortaa;
  border-bottom: 1px solid #00a0e3;
}

.error-cloud-content-go-home-desktop {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 2.4vw;
  background-color: #b171b3;
  font-family: Comfortaa;
  border-radius: 10vw;
  opacity: 1;
  color: white;
  font-size: 0.8vw;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  z-index: 99;
  margin-top: 2.5vw;
  margin-bottom: 3vw;
}

.error-cloud-content-go-home-mobile {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33vw;
  height: 6vw;
  background-color: #b171b3;
  font-family: Comfortaa;
  border-radius: 10vw;
  opacity: 1;
  color: white;
  font-size: 2.3vw;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  z-index: 99;
  margin-top: 2.5vw;
  margin-bottom: 13vw;
}

.error-cloud-content-go-home:hover {
  background-color: #ffc701;
}

.fiary-with-questions-container-desktop {
  text-align: center;
  width: 18%;
  height: auto;
  position: absolute;
  top: 12.5vw;
  left: 52vw;
  z-index: 999999;
}

.fiary-with-questions-container-mobile {
  text-align: center;
  width: 24%;
  height: auto;
  position: absolute;
  top: 23.5vw;
  left: 61vw;
  z-index: 999999;
}
