.blog-image-container {
  margin: 0 auto;
}
.blog-image-container-desktop {
  margin-bottom: 0.8vw;
  height: 18vw;
}
.blog-image-container-mobile {
  width: 90%;
  height: 348px;
}

.blog-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-item-image-desktop {
  border-radius: 40px;
}
.blog-item-image-mobile {
  border-radius: 10vw;
}
