.rating-dots-carousel-mobile {
  margin: 0 auto;
  margin-top: 5vw;
  margin-bottom: 10vw;
  z-index: 190;
}

.span-dots-carousel-mobile {
  height: 2.5vw;
  width: 2.5vw;
  background-color: #f5f0eb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1.7vw;
}

.active-dot-carousel,
.dot-carousel:hover {
  height: 2.5vw;
  width: 2.5vw;
  background-color: #ffc701;
  border-radius: 50%;
  display: inline-block;
}
