.privacy-policy-wrapper {
  background-color: #f8faff;
}

.privacy-policy-wrapper-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.privacy-policy-title-container-desktop {
  margin: 0 auto;
}

.privacy-policy-title-container h1 {
  color: #00a0e3;
  font: normal normal normal 6.55vw/7.92vw MyFontPHCond;
  text-transform: uppercase;
  position: relative;
}

.privacy-policy-title-container-desktop h1 {
  margin-top: -2vw;
}

.privacy-policy-title-container-mobile h1 {
  color: #00a0e3;
  font: normal normal normal 11.55vw/7.92vw MyFontPHCond;
  text-transform: uppercase;
  margin-top: -3vw;
  position: relative;
}

.privacy-policy-image-container {
  width: 104%;
  margin-top: -15vw;
  margin-left: -2vw;
}

.privacy-policy-image-container img {
  width: 100%;
  height: auto;
  z-index: -10;
}

.privacy-policy-image-container-mobile {
  width: 110%;
  margin-top: -13.8vw;
  margin-left: -5vw;
}

.privacy-policy-artwork-desktop {
  width: 30%;
  margin-left: 15vw;
}

.privacy-policy-artwork-mobile {
  width: 60%;
  margin-left: 20vw;
}

.privacy-policy-artwork img {
  width: 100%;
  height: auto;
}

.privacy-policy-content {
  background-color: white;
  margin: 0 auto;
  margin-top: -11.5vw;
  padding-bottom: 10vw;
}

.privacy-policy-content-mobile {
  padding-top: 3vw;
}

.privacy-policy-content p {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  color: #666666;
  font: normal normal normal 0.84vw/1.42vw Comfortaa;
  line-height: 1.5;
}

.privacy-policy-content p:first-child {
  margin-top: 6vw;
}

.privacy-policy-content-mobile p {
  font: normal normal 300 3.3vw/5.5vw Comfortaa;
  margin-bottom: 1vw;
}

.privacy-policy-content-info {
  padding-top: 7vw;
}
