
.country-picker-wrapper-desktop {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  position: absolute;
  bottom: 2vw;
}

.country-picker-flag-container-mobile {
  display: flex;
  justify-content: center;
  margin-left: 3vw;
}
.country-picker-flag-container-desktop {
  display: flex;
  width: 1.2vw;
  height: auto;
  margin-left: 0.7vw;
}

.all-country-picker-wrapper-desktop a {
  transition: 0.1s ease-in;
}

.all-country-picker-wrapper-desktop a:hover {
  transform: scale(1.05);
}
.country-picker-flag-container img {
  max-width: 100%;
}
div.menuDropDown
  > div.menuFooter
  > div.country-picker-mobile-container
  > div
  > div
  > img {
  margin-top: -3vw;
}

.country-picker-single-container-mobile > div > img {
  max-width: 100%;
  height: auto;
}

.country-picker-flag-container-mobile img {
  width: 7vw;
  height: 7vw;
}

.country-picker-flag-container-mobile img:-webkit-any-link {
  cursor: none;
}

.country-picker-flag-container-desktop img {
  width: 100%;
  height: auto;
}

.country-picker-single-container-mobile {
  display: flex;
  align-items: center;
  gap: 2vw;
  width: 10vw;
  height: 12vw;
  border-radius: 5vw;
  filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.1));
  background-color: white;
  margin-top: -10vw;
}

.country-picker-title-desktop,
.country-picker-title-selected-desktop {
  color: #666666;
  font: normal normal normal 0.8vw/0.75vw Comfortaa;
  text-transform: uppercase;
  cursor: pointer;
}

.country-picker-title-mobile,
.country-picker-title-selected-mobile {
  font-size: 4vw;
  color: #666666;
  margin-right: 3vw;
}

.all-country-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
  z-index: 999;
  position: absolute;
  right:0;

}

.all-country-picker-wrapper-desktop{
margin-top: -4vw;
left: 2vw;
}

.all-country-picker-wrapper-mobile {
  margin-top: -10vw;
}

.country-picker-title-selected-mobile {
  display: none;
}

.country-picker-single-container-desktop {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.1));
  border-radius: 2vw;
  width: 8.5vw;
  height: 2.2vw;
  background-color: white;
}

.country-picker-single-container-Global {
  background-color: #8f8dc3;
}

.country-picker-single-container:hover {
  cursor: pointer;
}

.country-picker-single-container-Global .country-picker-title {
  color: white;
}
