.control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 5px 0px 5px 0px;
    transition: all 200ms ease;
    display: flex;
    justify-content: space-between;
}

.Dropdown-placeholder.DDplaceholder.is-selected {
    text-align: left;
    font: normal normal bold 14px/15px Comfortaa;
    letter-spacing: 0px;
    color: #B171B3 !important;
    opacity: 1;

}

.Dropdown-option { 
    font: normal normal bold 14px/15px Comfortaa !important;
    letter-spacing: 0px !important;
    color: #707070 ;
    opacity: 1 !important;
    background-color: transparent !important;
}

.Dropdown-option.is-selected {

    background-color: transparent !important;
    color: #B171B3 !important;
}

.Dropdown-control:hover {
    /* box-shadow: 0 1px 0 rgb(0 0 0 / 6%); */
    box-shadow: none !important;
    background-color: transparent !important;
    color: #B171B3 !important;
}

.DDmenuClassName {
    text-align: left;
    font: normal normal bold 14px/15px Comfortaa;
    letter-spacing: 0px;
    color: #B171B3 !important;
    opacity: 1;
}
.Dropdown-placeholder.DDplaceholder {
    color: #B171B3 !important;
}

.ddArrow {
    width: 5%;
    height: 40%;
}

.arrow-open {
    width: 16px;
    height: 100%;
    background-image: url('../../assets/arrow-up.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.arrow-closed {
    width: 16px;
    height: 100%;
    background-image: url('../../assets/arrow-up.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
}