.ios-payment-content-desktop {
  display: flex;
  flex-direction: column;
  padding-top: 10vw;
  background-color: white;
  margin-top: -3vw;
  padding-bottom: 10vw;
}

.ios-payment-content-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 40vw;
  background-color: white;
  margin-top: -35vw;
}
