.accordion-container-mobile h5 {
  color: #666666;
  font: normal normal bold 2.9vw Comfortaa;
  white-space: nowrap;
}

.accordion-container-mobile p {
  color: #666666;
  font: normal 300 2.2vw Comfortaa;
  letter-spacing: 0.1px;
  line-height: 1.5;
  width: 90%;
  margin-left: 3vw;
}

.accordion-container-mobile:nth-child(even) {
  background-color: #f7f7f7;
  border-radius: 2.5vw;
}

.accordion-wrapper-mobile {
  display: flex;
  justify-content: space-between;
}

.showInfo .accordion-wrapper-mobile {
  display: flex;
  align-items: center;
}

.accordion-wrapper-left-container {
  display: flex;
  flex: 1 46%;
  margin-left: 0.5vw;
}

.accordion-icon-arrow-open-mobile {
  margin-top: -0.7vw;
  transition: 0.5s ease-in-out;
  transform: rotate(360deg);
  margin-left: -1vw;
  width: 5%;
  height: auto;
}

.accordion-icon-mobile-arrow {
  transition: 0.5s ease-in-out;
  margin-left: -1vw;
  width: 5%;
  height: auto;
}

.accordion-wrapper-middleIcon {
  display: flex;
  flex: 2 5%;
  align-items: center;
  justify-content: center;
}

.accordion-icon-mobile-cheked {
  width: 4.2vw;
  padding-bottom: 4.5vw;
  padding-top: 4.5vw;
  transition: 0.2s all;
}

.accordion-icon-mobile-cheked-rotate {
  width: 4.5vw;
  padding-bottom: 4.5vw;
  padding-top: 4.5vw;
}

.accordion-icon-freeIcon-mobile {
  width: 3.5vw;
  height: auto;
  padding-bottom: 4.5vw;
  padding-top: 4.5vw;
  margin-left: -1vw;
}

.accordion-wrapper-crown {
  display: flex;
  flex: 1 12%;
  justify-content: center;
  align-items: center;
}

.accordion-icon-mobile {
  width: 5.9vw;
  height: auto;
  transition: 0.2s ease-in;
}

.accordion-text-info-mobile {
  transition: all 5s ease;
  margin-top: -2vw;
  margin-left: 1.5vw;
}

.showInfo {
  background: #c590c7 !important;
  color: white;
  border-radius: 10px;
  text-align: left;
}
