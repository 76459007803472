.faq-header-wrapper-desktop {
  display: flex;
  flex-direction: column;
}

.faq-header-title {
  color: #f18868;
  text-transform: uppercase;
  position: relative;
}

.faq-header-title-desktop {
  font: normal normal 6.55vw/7.92vw MyFontPHCond;
  margin-top: -2vw;
}

.faq-header-title-mobile {
  font: normal normal 10.55vw/7.92vw MyFontPHCond;
  margin-top: -2vw;
}

.fea-header-image-container {
  width: 104%;
  margin-top: -15vw;
  margin-left: -2vw;
}

.fea-header-image-container img {
  width: 100%;
  height: auto;
  z-index: -10;
}

.artwork-container-desktop {
  width: 30%;
  margin-left: 15vw;
}

.artwork-container-mobile {
  width: 60%;
  margin-left: 20vw;
}

.artwork-container img {
  width: 100%;
  height: auto;
}

.faq-single-item-container {
  background-color: white;
  margin-top: -12vw;
  position: relative;
  padding-top: 8vw;
  padding-bottom: 12vw;
}


.faq-gif {
  max-width: 100%;
  height: auto;
}

.faq-header-wrapper-desktop .global-loader-wrapper-desktop {
  position: relative;
  height: 70vh;
  margin-top: -5vw;
  margin-bottom: 10vw;
}
