.blog-item-title-wrapper-desktop {
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-item-title {
  margin: 0 auto;
  text-transform: uppercase;
}
.blog-item-title-desktop {
  font: normal normal normal 2.1vw MyFontPHCond;
  width: 80%;
  color: hsla(0, 0%, 20%, 1);
  display: inline-block;
  margin-top: 1vw;
}
.blog-item-title-mobile {
  font: normal normal normal 11.1vw MyFontPHCond;
  width: 85%;
  letter-spacing: 0.35px;
  line-height: 1.1;
  color: #666666;
}

.blog-item-date {
  color: hsla(0, 0%, 40%, 1);
}
.blog-item-date-desktop {
  margin-top: 0.8vw;
  margin-bottom: 1vw;
  font: normal normal 300 0.63vw Comfortaa;
}
.blog-item-date-mobile {
  margin-top: 2.5vw;
  margin-bottom: 3.5vw;
  font: normal normal 300 3vw Comfortaa;
}

.blog-item-content {
  color: #666666;
  text-align: center;
}
.blog-item-content-desktop {
  font: normal normal normal 0.63vw Comfortaa;
  line-height: 1.2vw;
  height: 4.5vw;
}
.blog-item-content-mobile {
  font: normal normal normal 3.3vw Comfortaa;
  width: 90%;
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 2vw;
  line-height: 1.7;
}

.blog-item-content h1,
.blog-item-content h2,
.blog-item-content h3,
.blog-item-content h4,
.blog-item-content h5,
.blog-item-content h6 {
  font: normal normal 0.63vw Comfortaa;
  line-height: 1.2vw;
  font-style: normal;
  font-weight: normal;
}

.blog-item-content h1 strong em,
.blog-item-content h2 strong em,
.blog-item-content h3 strong em,
.blog-item-content h4 strong em,
.blog-item-content h5 strong em,
.blog-item-content h6 strong em {
  font-weight: normal;
  font-style: normal;
}

.blog-item-content-mobile-h1,
.blog-item-content-mobile h2,
.blog-item-content-mobile h3,
.blog-item-content-mobile h4,
.blog-item-content-mobile h5,
.blog-item-content-mobile h6 {
  font: normal normal normal 3.3vw Comfortaa;
  width: 90%;
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 2vw;
  line-height: 1.7;
}
