.termsModal {
    position: fixed;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    top: 0;
    right: 0;
    width: 30vw;
    min-width: 260px;
    height: 100vh;
    overflow: hidden;
    box-shadow: -18px 0px 55px #00000014;
    z-index: 100000;
}
.-mobile {
    width: 100%;
}
.controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5%;
}
.closeIcon {
    width: 2.5vh;
    height: 2.5vh;
    background-image: url('../../../../assets/x.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

 .termsContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: scroll;
    gap: 3vw;
    margin: 3vw;
}

.termsTitle {
    text-align: left;
    font: normal normal bold 17px/20px Comfortaa;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding-bottom: 5px;
}

.termsBody {
    text-align: left;
    font: normal normal normal 14px/20px Comfortaa;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}