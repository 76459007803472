.single-faq-item-wrapper {
  width: 75%;
  margin: 0 auto;
  border-radius: 2vw;
}

.single-faq-item-wrapper-desktop:hover {
  cursor: pointer;
}

.single-faq-item-wrapper-active-desktop {
  width: 75%;
  background-color: #9097cb;
  margin-bottom: 1vw;
}

.single-faq-item-wrapper-active-mobile {
  width: 75%;
  background-color: #9097cb;
  border-radius: 5vw;
}

.single-faq-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f7;
  margin-bottom: 2vw;
}

.single-faq-container-desktop {
  height: 5.96vw;
  border-radius: 2vw;
}

.single-faq-container-mobile {
  height: 15vw;
  border-radius: 5vw;
}

.single-faq-container-active {
  background-color: #9097cb;
}

.single-faq-container-active.single-faq-container p {
  color: white;
}

.single-faq-container p {
  margin-left: 3vw;
  color: #666666;
  text-align: left;
  text-transform: uppercase;
}

.single-faq-container-desktop p {
  font: normal normal normal 2.15vw/2.6vw MyFontPHCond;
}

.single-faq-container-mobile p {
  margin-left: 5vw;
  font: normal normal normal 4.6vw/4.6vw MyFontPHCond;
  width: 70%;
}

.single-faq-item-content {
  width: 80%;
  color: white;
  text-align: left;
}

.single-faq-item-content-desktop p {
  margin-left: 3vw;
  font: normal normal normal 0.83vw/1.42vw Comfortaa;
  line-height: 1.5vw;
  margin-bottom: 1vw;
}

.single-faq-item-content-mobile {
  margin-bottom: 4vw;
  width: 90%;
}

.single-faq-item-content ol:last-of-type,
.single-faq-container ul:last-of-type {
  padding-bottom: 5vw;
}

.single-faq-item-content-mobile p {
  margin-left: 5vw;
  font: normal normal normal 2.6vw/3.5vw Comfortaa;
  margin-bottom: 3vw;
}

.single-faq-item-content-desktop p:first-child {
  margin-top: -3vw;
}

.single-faq-item-content-desktop p:last-child {
  padding-bottom: 4vw;
}

.single-faq-item-content-mobile p:last-child {
  padding-bottom: 6vw;
}

.single-faq-item-content-desktop ul,
.single-faq-item-content-desktop ol {
  width: 90%;
  margin: 0 auto;
  font: normal normal normal 0.83vw/1.5vw Comfortaa;
  color: white;
  margin-bottom: 1vw;
}

.single-faq-item-content-mobile ul,
.single-faq-item-content-mobile ol {
  width: 80%;
  margin: 0 auto;
  color: white;
  margin-bottom: 5vw;
  text-align: left;
  font: normal normal normal 2.6vw/3.5vw Comfortaa;
}

.single-faq-item-content-mobile ul li,
.single-faq-item-content-mobile ol li {
  margin-bottom: 2vw;
}

.single-faq-item-content ul li,
.single-faq-item-content ol li {
  list-style-type: disc;
  text-align: left;
  margin-left: 2vw;
}

.faq-arrow-container-desktop {
  width: 3%;
  margin-right: 3vw;
  transition: 0.5s ease-in-out;
}

.faq-arrow-container-active-desktop {
  transition: 0.8s ease-in-out;
  transform: rotate(360deg);
  width: 3%;
  margin-right: 3vw;
}

.faq-arrow-container-mobile {
  width: 7%;
  margin-right: 5vw;
  transition: 0.5s ease-in-out;
}

.faq-arrow-container-active-mobile {
  transition: 0.8s ease-in-out;
  transform: rotate(360deg);
  width: 7%;
  margin-right: 5vw;
}

.faq-arrow-container-active img {
  width: 100%;
  height: auto;
}

.faq-arrow-container img {
  width: 100%;
  height: auto;
}

.faq-arrow-container-active img:hover,
.faq-arrow-container img {
  cursor: pointer;
}
