.App-home {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.main-desktop {
  flex: 1;
  display: flex;
}

.main-mobile {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.main-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
  width: 10%;
  background-color: #f8f4f0;
}

.right-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: right;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  right: 0;
}

.right-bar-desktop {
  width: 20%;
  top: 50vw;
}

.right-bar-mobile {
  width: 20%;
  top: 330%;
}

.left-bar-desktop {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: left;
  left: 0;
  top: 60vw;
  position: absolute;
  z-index: 20;
  pointer-events: none;
  width: 10%;
}

.left-bar-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: left;
  left: 0;
  top: 170%;
  width: 20%;
  position: absolute;
  z-index: 9;
  pointer-events: none;
}

.home-section {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.home-section-desktop {
  flex-direction: row;
  gap: 4vw;
  margin-bottom: 10vw;
}

.home-section-mobile {
  flex-direction: column;
  margin-top: 20vw;
  margin-bottom: 10vw;
}

.home-section-image-container-desktop {
  width: 50%;
}

.home-section-content-container-desktop {
  width: 50%;
  z-index: 99;
  margin-top: 1vw;
}

.home-section-content-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 99;
  margin-bottom: 20vw;
}

.home-section-title {
  color: #666666;
  text-transform: uppercase;
}

.home-section-title-desktop {
  text-align: left;
  font: normal normal normal 5vw MyFontPHCond;
}

.home-section-title-mobile {
  text-align: center;
  margin-top: 6vw;
  font: normal normal normal 12vw MyFontPHCond;
}

.home-section-title span {
  color: var(--primaryColor);
}

.home-section-subtitle {
  color: #707070;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: bold;
}
.home-section-subtitle-desktop {
  text-align: left;
  font: normal normal bold 0.92vw Comfortaa;
  opacity: 1;
  width: 88%;
  margin-top: 0.5vw;
  margin-bottom: 1.5vw;
}
.home-section-subtitle-mobile {
  text-align: center;
  font: normal normal bold 3.3vw Comfortaa;
  opacity: 0.8;
  width: 100%;
  margin-top: 3vw;
  margin-bottom: 8vw;
}

.home-section-paragraph {
  color: #707070;
}

.home-section-paragraph-desktop {
  text-align: left;
  font: normal normal 300 0.83vw Comfortaa;
  margin-bottom: 1.5vw;
  line-height: 1.4vw;
  width: 90%;
}

.home-section-paragraph-mobile {
  text-align: center;
  font: normal normal 300 3.3vw/1.9vw Comfortaa;
  opacity: 0.7;
  margin-top: -2vw;
  line-height: 1.8;
  margin-bottom: 4vw;
}

.home-section-button {
  text-transform: uppercase;
  background-color: var(--primaryColor);
  font-family: Comfortaa;
  color: white;
}

.home-section-button-desktop {
  width: 8.4vw;
  border-radius: 10vw;
  font-size: 0.78vw;
  padding: 0.94vw 1.55vw 0.94vw 1.55vw;
  margin-top: 1.5vw;
  transition: 0.1s ease-in-out;
}
.home-section-button-desktop:active,
.home-section-button-desktop:hover {
  background-color: #ffc701;
  cursor: pointer;
}

.home-section-button-mobile {
  width: 35.5vw;
  font: normal normal bold 2.9vw/3vw Comfortaa;
  border-radius: 29px;
  font-weight: bold;
  padding: 3.3vw 3vw 3.3vw 3vw;
  margin-top: 5vw;
}

.home-section-button-mobile:-webkit-any-link {
  cursor: none;
}

a {
  text-decoration: none;
  color: white;
}

.App-home .footer-component-desktop {
  margin-top: 0;
}

.App-home .rating-component-desktop {
  margin-bottom: 20vw;
  margin-top: -5vw;
}

.App-home .rating-component-mobile {
  padding-bottom: 10vw;
  margin-top: -30vw;
}

.App-home .rating-container-mobile {
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.App-home .carousel-image-container-home-image-mobile {
  margin-top: -14vw;
}
.App-home .rating-container-mobile .slider-control-bottomcenter {
  top: 68vw !important;
}
