.carousel-image-wrapper-desktop {
  display: flex;
  width: 100%;
}

.carousel-image-wrapper-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.carousel-image-container-home-desktop {
  width: 100%;
  margin-top: -7vw;
  z-index: 0;
}

.carousel-image-container-home-mobile {
  width: 100%;
  /* margin-bottom: 10vw; */
  margin-bottom: -6vw;
  z-index: 0;
}

.carousel-image-container-home-image-desktop {
  width: 100%;
  height: auto;
  margin-top: -20vw;
}

.carousel-image-container-home-image-mobile {
  width: 130%;
  height: auto;
  margin-top: -10vw;
}

.carousel-image-container-pregnancy-desktop {
  width: 120%;
  height: auto;
  transform: rotate(180deg);
  position: absolute;
  margin-top: -7%;
  left: -15vw;
}

.carousel-image-container-desktop {
  margin-top: -13vw;
  width: 100%;
  z-index: 0;
}

.carousel-image-container-mobile {
  width: 100%;
  margin-bottom: -37vw;
}

.carousel-container-image-mobile {
  width: 130%;
  height: auto;
  margin-top: -23vw;
  margin-left: -20vw;
}

.carousel-container-image-desktop {
  width: 100%;
}

.slider-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
