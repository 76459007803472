.Feia-logo-menu {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 3vw;
  padding-left: 8%;
}

.gaia-logo-menu {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 3vw;
}
.ads-menu {
  width: 60%;
}
.scroll-indicator-controller {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100vh;
  z-index: 100;
  align-items: center;
  justify-content: space-around;
  background: rgb(255, 255, 255, 0.97);
  box-shadow: -18px 0px 55px #00000029;
}
.pageCluster {
  margin: -3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.pageCluster .indicator {
  width: 0.3vw;
  height: 0.3vw;
}
.pageCluster .indicator.active {
  background: #ffc701;
}
.pageCluster a {
  display: inline-block;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
}
.indicator {
  width: 0.7vw;
  height: 0.7vw;
  /* margin: 2.1vw 3vw; */
  position: relative;
  border-radius: 50%;
  border: 1px solid #b171b3;
  background: #b171b3;
  cursor: pointer;
  transition: 0.4s ease;
  will-change: transition;
}
.indicator.active span {
  color: #ffc701;
  position: absolute;
  left: calc(100% + 8px);
  white-space: nowrap;
  top: 50%;
  line-height: 1vw;
  width: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-50%) translateX(10px);
  transition: all 0.4s ease;
  will-change: transition;
  padding: 1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 3vw;
  text-align: left;
  font-size: 1vw;
  font-stretch: 14%;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  font-family: Comfortaa;
  letter-spacing: 0px;
  text-transform: uppercase;
}
.indicator span {
  color: #b171b3;
  position: absolute;
  left: calc(100% + 8px);
  white-space: nowrap;
  top: 50%;
  line-height: 1vw;
  width: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-50%) translateX(10px);
  transition: all 0.4s ease;
  will-change: transition;
  padding: 1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 3vw;
  text-align: left;
  font-size: 1vw;
  font-stretch: 14%;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  font-family: Comfortaa;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.dots-wrapper__text {
  text-decoration: none;
  color: #b171b3;
  position: absolute;
  left: calc(100% + 8px);
  white-space: nowrap;
  top: 50%;
  line-height: 1vw;
  width: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-50%) translateX(10px);
  transition: all 0.4s ease;
  will-change: transition;
  padding: 1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 3vw;
  text-align: left;
  font-size: 1vw;
  font-stretch: 14%;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: bold;
  font-family: Comfortaa;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.dots-wrapper {
  display: flex;
  position: relative;
}
@media (hover: none) {
  .indicator span {
    display: none;
  }
  .dots-wrapper .dots-wrapper__text {
    display: none;
  }
}
@media (hover: hover), (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .indicator:hover span {
    width: auto;
    opacity: 1;
    overflow: visible;
    transform: translateY(-50%) translateX(5px);
  }

  .dots-wrapper:hover .dots-wrapper__text {
    width: auto;
    opacity: 1;
    overflow: visible;
    /* transform: translateY(-50%) translateX(5px); */
  }
}
.dots-wrapper:hover {
  width: auto;
  opacity: 1;
  overflow: visible;
  transform: scale(1);
}
.dots-wrapper:hover .dots-wrapper__text {
  width: auto;
  opacity: 1;
  overflow: visible;
  transform: translateY(-50%) translateX(5px);
}
.dots-wrapper__text.active-text {
  color: #ffc701;
}
.indicator.active {
  background: white;
  border-color: #ffc701;
  opacity: 1;
}
.indicator.active-inner {
  background: white;
  border-color: #ffc701;
  opacity: 1;
}

.mobileMenu {
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: space-between; */
  width: 100%;
  height: 15vw;
  position: absolute;
  transition: 0.1s ease-in;
  z-index: 200;
}

.mobileMenuPregnancy {
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: space-between; */
  width: 100%;
  height: 15vw;
  position: absolute;
  transition: 0.1s ease-in;
  z-index: 99999;
  background-color: transparent;
}
.mobileMenuPregnancy input {
  background-color: transparent;
}
.mobileNavbarWhite {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  height: 15vw;
  position: absolute;
  z-index: 999999;
  transition: 0.1s ease-in;
  box-shadow: -18px 0px 55px #0000000d;
  background: rgb(255, 255, 255, 0.97);
}
.searchButton {
  position: absolute;
  top: 3vw;
  right: 3vw;
  border-radius: 2vw;
  background-color: #b171b3;
  width: 2.5vw;
  height: 2.5vw;
  justify-content: center;
  background-size: 1.3vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/search.svg");
}

.centerMenuLogo-container {
   display: inline-block;
   width: 30%;
   margin:  0 auto;
}

.centerMenuLogo-container img {
  max-width: 100%;
  height: auto;
  margin-left: -12vw;
}

.centerMenuLogo-container:-webkit-any-link {
  cursor: none;
}
.menuDropDown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100vw;
  box-shadow: -18px 0px 55px #0000000d;
  background-color: #ffffff;
  z-index: 99998;
  opacity: 0.98;
  border-bottom-right-radius: 6%;
  border-bottom-left-radius: 6%;
}

.menuItems {
  flex: 5 1;
  padding: 4vw;
  margin-left: 6vw;
  text-align: left;
  font-size: 4.5vw;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}
.mobileMenuItem {
  color: #868281;
  font-family: "Comfortaa";
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 600;
}
.mobileMenuItem a {
  color: #868281;
  font-family: "Comfortaa";
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 600;
}

.appsList {
  color: #868281;
  font-family: "Comfortaa";
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 4vw;
  margin-top: 15vw;
  text-transform: uppercase;
}

.appsList > div {
  margin-bottom: 4vw;
  width: 70%;
  
}
.closed {
  display: none;
}

.menu-fb {
  width: 100%;
  height: 50%;
  background-image: url("../../../assets/menu-fb.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.menu-insta {
  width: 100%;
  height: 50%;
  background-image: url("../../../assets/menu-insta.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.menu-fairy {
  width: 50%;
  height: 90%;
  background-image: url("../../../assets/menu-fairy.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.chevronUp {
  width: 10vw;
  height: 10vw;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/chevronUp.svg");
  position: absolute;
  overflow: hidden;
  bottom: 3vw;
  right: 4vw;
  z-index: 4;
  transition: 0.2s ease-in;
}
.chevronUp:active,
.chevronUp:hover {
  transform: scale(1.3);
  background-image: url("../../../assets/chevronUp@2x.svg");
  /* cursor: pointer; */
}

.descktop-chevronUp {
  width: 2.2vw;
  height: 2.2vw;
}
.descktop-chevronUp:hover {
  cursor: pointer;
}
.dashesPath {
  width: 1vw;
  height: 68vh;
  background-image: url("../../../assets/menu-dashLine.png");
  /* background-size: contain; */
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  top: 15vh;
  z-index: 0;
  pointer-events: none;
}
.version {
  position: absolute;
  bottom: 5px;
  font-size: 0.9vw;
  color: #868281;
}
.mobile-version {
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
  color: #868281;
}

#hamburgerIcon {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  width: max(6vw, 20px);
  height: max(6vw, 20px);
  position: relative;
  margin-left: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  z-index: 1;
}

#hamburgerIcon span {
  display: block;
  position: absolute;
  height: 0.55vw;
  width: 100%;
  background: #b171b3;
  border-radius: 20vw;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#hamburgerIcon span:last-child {
  width: 60%;
}

#hamburgerIcon span:nth-child(1) {
  top: 0px;
}

#hamburgerIcon span:nth-child(2) {
  top: 45%;
}

#hamburgerIcon span:nth-child(3) {
  top: 90%;
}

#hamburgerIcon.openHamburgerIcon span:nth-child(1) {
  top: 9px;
  height: 0.55vw;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
#hamburgerIcon.openHamburgerIcon span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#hamburgerIcon.openHamburgerIcon span:nth-child(3) {
  top: 9px;
  width: 100%;
  height: 0.55vw;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.scroll-indicators-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: -3.3vw;
  min-height: 70vh;
}
.menu-links {
  text-decoration: none;
  color: #868281;
  font-family: "Comfortaa";
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 4vw;
}
.menu-links:-webkit-any-link {
  cursor: none;
}
.dots-wrapper__text span:hover {
  cursor: pointer;
}
