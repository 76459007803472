.rulesContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: scroll;
    gap: 3vw;
    margin: 3vw;
}

.rulesTitle {
    text-align: left;
    font: normal normal bold 17px/20px Comfortaa;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding-bottom: 5px;
}

.rulesBody {
    text-align: left;
    font: normal normal normal 13px/18px Comfortaa;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}