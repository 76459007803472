.welcome-page {
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
  background-color: #f8fcfe;
  z-index: 10;
}

.welcome-wrapper-desktop {
  display: flex;
}

.welcome-wrapper-mobile {
  display: flex;
  flex-direction: column;
  gap: 8vw;
}

.welcome-page-desktop {
  height: 50vw;
}

.welcome-page-mobile {
  /* height: 100vh; */
}

.content-container-desktop {
  margin-left: 9%;
  margin-top: 4.5vw;
  width: 50%;
}

.images-container-desktop {
  width: 50%;
}

.images-container-mobile {
  width: 90%;
  margin: 0 auto;
}

.phone-wrapper-desktop {
  width: 70%;
}

.phone-wrapper-mobile {
  margin: 0 auto;
  width: 80%;
}

.images-container img {
  max-width: 100%;
  height: auto;
}

.content-container-mobile {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
}

.welcome-title {
  text-transform: uppercase;
  color: #666;
}

.welcome-title-desktop {
  text-align: left;
  font: normal normal 4.75vw MyFontPHCond;
  line-height: 1;
}

.welcome-title-mobile {
  text-align: center;
  width: 75vw;
  font: normal normal normal 12.5vw MyFontPHCond;
  line-height: 1;
  opacity: 1;
}

.welcome-title span {
  color: var(--primaryColor);
}

.welcome-content {
  color: #707070;
}

.welcome-content-desktop {
  text-align: left;
  font: normal normal 300 1.14vw Comfortaa;
  letter-spacing: 0px;
  line-height: 1.8vw;
  opacity: 0.8;
  width: 60%;
}

.welcome-content-mobile {
  text-align: center;
  font: normal normal 300 3.2vw/5.3vw Comfortaa;
  letter-spacing: 0px;
  opacity: 0.9;
  margin-top: 2vw;
  width: 50%;
}

.welcome-button {
  background-color: #ebb146;
  transition: 0.1s ease-in-out;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 99;
  float: left;
}

.welcome-button-mobile {
  border-radius: 10vw;
  font: normal normal 2.9vw/2.5vw Comfortaa;
  width: 30%;
  padding: 4vw 3vw 3vw 3vw;
  margin-top: 5vw;
}

.welcome-button-desktop {
  width: 7.86vw;
  font: normal normal 0.8vw Comfortaa;
  border-radius: 20vw;
  padding: 0.9vw 1.7vw 0.8vw 1.7vw;
  margin-top: 3vw;
}

.welcome-button-desktop:active,
.welcome-button-desktop:hover {
  background-color: var(--primaryColor);
  cursor: pointer;
}

.welcome-button-mobile:active {
  background-color: var(--primaryColor);
}

.welcome-button-mobile:-webkit-any-link {
  cursor: none;
}

.welcome-phones {
  pointer-events: none;
  background-image: url("../assets/phone.png");
  background-repeat: no-repeat;
  background-size: contain; /* or contain, depending on your preference */
}

.welcome-phones-desktop {
  width: 48vw;
  height: 40vw;
  flex: 1 1;
  position: absolute;
  right: -2vw;
}

.welcome-phones-mobile {
  width: 100%;
  height: 95vw;
  margin-top: 7vw;
  background-position: center center;
}

.welcome-subtitle-desktop {
  color: 666;
  font: normal normal 1.55vw/2.7vw StudioScript;
  text-align: left;
  margin-top: 2vw;
  margin-bottom: 0.7vw;
}

.welcome-subtitle-mobile {
  color: 666;
  font: normal normal 5.55vw/4.7vw StudioScript;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 4vw;
  line-height: 1.4;
}

.flower-container-desktop {
  display: flex;
  justify-content: flex-end;
  margin-top: -38%;
}

.flower-container-mobile {
  margin-top: -55%;
  width: 140%;
  margin-left: -40%;
}
