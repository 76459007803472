.error-wrapper-desktop {
  margin-top: -10vw;
  margin-bottom: 10vw;
}

.error-fairy-container-desktop {
  text-align: center;
  width: 18%;
  height: auto;
  position: absolute;
  top: 9.5vw;
  left: 46vw;
  z-index: 999999;
}

.error-fairy-container-mobile {
  text-align: center;
  width: 24%;
  height: auto;
  position: absolute;
  top: 38.5vw;
  left: 55vw;
}

.error-wrapper-title {
  margin: 0 auto;
}

.error-wrapper-title-desktop {
  font: normal normal normal 27.3vw/34.25vw MyFontPHCond;
  color: #9097cb;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
}

.error-wrapper-title-mobile {
  font: normal normal normal 50.3vw/45.25vw MyFontPHCond;
  color: #9097cb;
  background-clip: text;
  /* position: relative; */
  background-clip: text;
  z-index: 4;
  margin-top: 15vw;
}

.error-wrapper-title::after {
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 3.8vw #fff;
  left: 0vw;
  z-index: -3;
}

.error-description-desktop {
  color: #333;
  width: 60%;
  margin: 0 auto;
  line-height: 1.4vw;
}
