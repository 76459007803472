.blog-image {
  max-width: 100%;
  height: auto;
}

.home-blogs-mobile {
  display: flex;
  flex-direction: column-reverse !important;
  margin-bottom: 20vw!important;
}

.home-blogs-desktop .home-section-content-container {
  margin-left: 10vw;
  width: 35% !important;
}

.home-blogs-desktop .home-section-subtitle {
  width: 80%;
  line-height: 1.6;
}

.comming-soon-title {
  text-align: left;
  font: normal normal normal 2vw MyFontPHCond;
  color: #f18868;
  text-transform: uppercase;
  border-bottom: 2px solid #f18868;
}

.comming-soon-title-desktop {
  width: 30%;
}

.comming-soon-title-mobile {
  text-align: center;
  font: normal normal normal 8vw MyFontPHCond;
  margin-top: 10vw;
}

.home-blogs-mobile .home-section-title-mobile {
  margin-top: 1vw;
}

.home-blogs-mobile .home-section-subtitle-mobile {
  width: 90%;
  line-height: 1.7;
}
