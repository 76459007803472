.App {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.ios-payment-main {
  background-color: #f8f4f0;
}

.ios-payment-main-desktop {
  flex: 1;
  display: flex;
}

.ios-payment-main-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ios-payment-main-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
}
