.sponsors-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.sponsors-images-container-desktop {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7vw;
}

.sponsors-images-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -8vw;
}

.sponsors-image-container-mobile {
  margin: 0 auto;
}

.logo-image-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image-mobile {
  margin: 0 auto;
  display: block;
  width: 50%;
  height: 100%;
  background-size: contain;
  margin-top: 3vw;
  margin-bottom: 3vw;
}

.sponsors-logo {
  max-width: 100%;
  /* aspect-ratio: 3/2; */
  object-fit: contain;
  height: auto;
}
.sponsors-logo-desktop {
  filter: brightness(0) saturate(100%) invert(61%) sepia(9%) saturate(1352%)
    hue-rotate(196deg) brightness(98%) contrast(89%);
}
.sponsors-logo-desktop:hover {
  filter: none;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

.slick-slider-wrapper-desktop {
  width: auto !important;
}
