.accordion-container-desktop {
  display: flex;
  justify-content: space-between;
  border-radius: 17px;
  height: 5.95vw;
  text-align: left;
}

.accordion-content-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: 1vw;
  padding: 0rem 0rem 0rem 1rem;
}

.accordion-content-wrapper-desktop {
  margin-left: 0.1vw;
  flex: 1 60%;
}

.accordion-content-wrapper-desktop h5 {
  color: #b171b3;
  font: normal normal bold 0.9vw Comfortaa;
  margin-bottom: 0.4vw;
  letter-spacing: 0.5px;
}

.accordion-content-wrapper-desktop p {
  color: #666666;
  font: normal normal 300 0.73vw/1.1vw Comfortaa;
  width: 96%;
}

.accordion-icon-container-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 8%;
}

.accordion-icon-checked-desktop {
  width: 1.85vw;
  height: auto;
}

.accordion-icon-crown {
  width: 1.83vw;
  height: 100%;
}

.accordion-icon-freeIcon-desktop {
  width: 1.2vw;
  height: auto;
  margin-left: 0.1vw;
}

.accordion-container-desktop:nth-child(even) {
  background-color: #f7f7f7;
}

.accordion-icon-wrapper-desktop {
  flex: 1 30%;
}

.crown-wrapper-desktop {
  flex: 1 20%;
}
