.feiaInput {
    background: #ffffff;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(148, 151, 153, 0.5);
    color: #b171b3;
    font: normal normal bold 14px/15px Comfortaa;  
    resize: none;
    width: 100%;
    max-height: 80px;
    padding-top: 0.1vw;
}

.feiaInput:focus {
    outline: none;
}

.feiaInput::placeholder {
    color: #b171b3;
}

.invalidInput {
    position: relative;
    text-align: left;
    font: normal normal bold 14px/15px Comfortaa;
    letter-spacing: 0px;
    opacity: 1;
    width: 100%;
    border: none;
    border-radius: 0;
    padding: inherit;
    outline: none;
    border-bottom: 1px solid #95989A;
    color: red;
}

.invalidInput::-webkit-input-placeholder,
.feiaInput::-webkit-input-placeholder {
    text-align: left;
    letter-spacing: 0px;
    color: #B171B3;
    opacity: 1;
}

.invalidInput::-moz-placeholder,
.feiaInput::-moz-placeholder {
    text-align: left;
    letter-spacing: 0px;
    color: #B171B3;
    opacity: 1;
}

.invalidInput:-ms-input-placeholder,
.feiaInput:-ms-input-placeholder {
    text-align: left;
    letter-spacing: 0px;
    color: #B171B3;
    opacity: 1;
}

.invalidInput:-moz-placeholder,
.feiaInput:-moz-placeholder {
    text-align: left;
    letter-spacing: 0px;
    color: #B171B3;
    opacity: 1;
}

.underLineInfo {
    display: flex;
    font-size: 0.5em;
    align-items: flex-start;
    justify-content: space-between;
}

.errorMsg {
    color: rgb(245, 48, 48);
}