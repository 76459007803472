.carousel-page-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 36vw; */
  background: #ffffff;
  opacity: 1;
  z-index: 999;
  margin-top: -0.6vw;
}

.carousel-page-mobile {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  z-index: 999;
  background: #ffffff;
}

.carousel-page-home-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  opacity: 1;
  z-index: 999;
  margin-top: -0.6vw;
}

.carousel-content-desktop {
  width: 65%;
  margin: 0 auto;
  letter-spacing: 0px;
  color: #666666;
  text-align: left;
  margin-bottom: 2.5vw;
}

.carousel-content-desktop div {
  margin-bottom: 0.7vw;
}

.carousel-content-mobile {
  letter-spacing: 0px;
  color: #666666;
  font: normal normal 2.7vw Comfortaa;
  text-align: left;
  margin-bottom: 2vw;
}

.carousel-content-mobile div {
  margin-bottom: 0.7vw;
}

.carousel-content-mobile {
  letter-spacing: 0.2px;
  color: #707070;
  opacity: 1;
  margin-top: 2vw;
  text-align: center;
  font: normal normal 300 3vw Comfortaa;
  width: 79%;
  line-height: 5.5vw;
}

.carousel {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center;
}

.carousel-desktop {
  margin: 0 auto;
  margin-top: 1.5vw;
  height: 25vw;
  margin-bottom: 5vw;
}

.carousel-mobile {
  width: 100%;
}

.up-flower {
  pointer-events: none;
  background-image: url("../../assets/carouselUpFlower.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
  background-size: contain;
}

.up-flower-desktop {
  width: 100%;
  height: 9vw;
  margin-bottom: -5vw;
  margin-top: -2.5vw;
  margin-left: -15.5vw;
}

.up-flower-mobile {
  width: 100%;
  height: 31vw;
  margin-bottom: -25vw;
  margin-left: -2vw;
}

.down-flower {
  pointer-events: none;
  background-image: url("../../assets/carouselDownFlowers.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.down-flower-desktop {
  width: 100%;
  height: 8vw;
  margin-top: -4.5vw;
  margin-left: -8.7vw;
  background-position-y: bottom;
  background-position-x: center;
}

.down-flower-mobile {
  width: 100%;
  height: 35vw;
  margin-top: -28vw;
  margin-left: 8vw;
}

.slider-slide:first-child .carousel-card {
  background-color: #b171b3;
  opacity: 0.8;
}

.carousel-button-white {
  background-color: white;
  color: #333333;
}

.carousel-card-desktop:active,
.carousel-card-desktop:hover {
  transform: scale(1.07);
  cursor: pointer;
}

.carousel-card-desktop {
  width: 17.9vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 3% 0 3%;
  height: 22.79vw;
  justify-content: center;
  border-radius: 42px;
  transition: 0.1s ease-in;
  background-color: rgb(249, 246, 243, 0.8);
  position: relative;
}

.carousel-card-mobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 2vw;
  height: 85vw;
  width: 53vw;
  border-radius: 42px;
  box-shadow: none;
  outline: none 0px;
  background-color: rgb(249, 246, 243, 0.8);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  border-radius: 25px;
  background-color: rgb(249, 246, 243, 0.8);
}

.card-image-mobile {
  height: 35vw;
  max-width: 100%;
}

.card-image-desktop {
  height: 9vw;
  max-width: 100%;
}

.card-item-title-desktop {
  font: normal normal normal 2.5vw MyFontPHCond;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: white;
  /* margin-bottom: 0.5vw; */
}

.card-item-title-mobile {
  font: normal normal normal 8.5vw MyFontPHCond;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: white;
  margin-bottom: 1vw;
}

.card-info {
  color: #707070;
  font-family: Comfortaa;
}

.card-info-desktop {
  font-size: 0.64vw;
  width: 75%;
  line-height: 2;
  margin-top: -2vw;
  height: 5.5vw;
}

.card-info-mobile {
  font-size: 2.5vw;
  width: 85%;
  letter-spacing: 0.3px;
  line-height: 1.7;
  margin-top: -4vw;
}

.card-info-active {
  font-family: Comfortaa;
  color: white;
}

.card-info-active-desktop {
  font-size: 0.64vw;
  width: 75%;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 2;
  margin-top: -2vw;
}

.card-info-active-mobile {
  font-size: 2.5vw;
  width: 85%;
  letter-spacing: 0.3px;
  opacity: 1;
  line-height: 1.7;
  margin-top: -4vw;
}

.carousel-button-desktop {
  background-color: #b171b3;
  width: 9vw;
  font-family: Comfortaa;
  border-radius: 29px;
  opacity: 1;
  color: white;
  text-transform: uppercase;
  font-size: 0.65vw;
  padding: 0.76vw 1vw 0.76vw 1vw;
  margin-top: -1vw;
}

.carousel-button-white-desktop {
  width: 9vw;
  font-family: Comfortaa;
  border-radius: 29px;
  opacity: 1;
  color: #333;
  text-transform: uppercase;
  font-size: 0.65vw;
  padding: 0.76vw 1vw 0.76vw 1vw;
  margin-top: -1vw;
}

.carousel-button-white-mobile {
  width: 35vw;
  border-radius: 30px;
  text-transform: uppercase;
  opacity: 1;
  color: #333;
  font: normal normal 2.4vw Comfortaa;
  padding: 2.7vw 1vw 2.7vw 1vw;
}

.carousel-button-mobile {
  width: 35vw;
  background-color: #b171b3;
  border-radius: 30px;
  text-transform: uppercase;
  opacity: 1;
  color: white;
  font: normal normal 2.4vw Comfortaa;
  padding: 2.7vw 1vw 2.7vw 1vw;
}

.carousel-button-deactived-mobile {
  opacity: 0;
}

.deactive-card-pregnancy {
  background-color: rgb(177, 113, 179, 0.8);
}

.deactive-card-weaning {
  background-color: #add181;
}

.deactive-card-ChildDevelopment {
  background-color: var(--childDev-color);
}

.deactive-card-mobile-pregnancy {
  background-color: rgb(177, 113, 179, 0.8);
}

.deactive-card-mobile-weaning {
  background-color: #add181;
}

.deactive-card-mobile-ChildDevelopment {
  background-color: var(--childDev-color);
}

.deactivated-desktop {
  opacity: 0;
}

.carouselMobile .slider-list {
  cursor: none !important;
}

.deactive-card-pregnancy-desktop .carousel-button-desktop,
.deactive-card-weaning-desktop .carousel-button-desktop,
.deactive-card-ChildDevelopment-desktop .carousel-button-desktop {
  opacity: 0;
}

.hover-mobile,
.carousel-button-mobile:hover {
  background-color: #ebb146 !important;
}

.new-label {
  align-items: center;
  background-color: #f18868;
  color: white;
  text-transform: uppercase;
  position: absolute;
}

.new-label-mobile {
  padding: 1.3vw 2.4vw;
  border-radius: 4vw;
  color: white;
  font: normal normal bold 3vw Comfortaa;
  text-transform: uppercase;
  margin-left: -28vw;
  margin-top: -35vh;
  margin-bottom: 1vw;
  position: absolute;
}

.new-label-desktop {
  align-items: center;
  background-color: #f18868;
  padding: 0.3vw 0.9vw;
  border-radius: 4vw;
  color: white;
  font: normal normal bold 0.625vw Comfortaa;
  text-transform: uppercase;
  margin-left: -11vw;
  margin-top: -17.5vw;
  margin-bottom: 1vw;
  position: absolute;
}

.deactive-card-pregnancy-desktop img {
  margin-top: 1vw;
}

.new-carousel-wrapper {
  margin-top: 5vw;
}

.carousel-button-desktop:hover {
  background-color: #ebb146;
}

.plan-renewal-desktop {
  width: 80%;
  text-align: center;
  color: #999999;
  font: normal 300 0.6vw/1vw Comfortaa;
  margin-top: 0.7vw;
}

.plan-renewal-mobile {
  width: 90%;
  text-align: center;
  color: #999999;
  font: normal 300 2.1vw/2.5vw Comfortaa;
  margin-top: 4vw;
}

.card-heading-desktop {
  font: normal normal normal 2.7vw/3vw MyFontPHCond;
  color: white;
  margin-bottom: 2vw;
  margin-top: -1vw;
}

.card-heading-mobile {
  font: normal normal normal 9.7vw/10vw MyFontPHCond;
  color: white;
  margin-bottom: 1vw;
  margin-top: -1.5vw;
}

.container-info-desktop {
  margin-bottom: 1.5vw;
}

.card-duration-desktop {
  color: #666666;
  text-transform: uppercase;
  font-size: 0.8vw;
  margin-top: 0.7vw;
}

.card-duration-mobile {
  color: #666666;
  text-transform: uppercase;
  font-size: 3.8vw;
  margin-top: 1.7vw;
}

.card-price-desktop {
  color: #ebb146;
  text-transform: uppercase;
  font-size: 1.5vw;
  font-weight: bolder;
  margin: 0.5vw 0;
  font-family: Montserrat;
}

.card-price-mobile {
  color: #ebb146;
  text-transform: uppercase;
  font-size: 6.5vw;
  font-weight: bold;
  margin: 2vw 0;
}

.carousel-func-desktop {
  display: flex;
  gap: 0.3vw;
  align-items: center;
}

.carousel-func-mobile {
  display: flex;
  gap: 2.3vw;
  align-items: center;
}

.carousel-func-desktop img {
  width: 8%;
  height: auto;
}

.x-icon-mobile {
  width: 8% !important;
  height: auto;
  margin-right: 1vw;
}

.carousel-func-mobile img {
  width: 10%;
  height: auto;
}

.x-icon-desktop {
  width: 5% !important;
  height: auto;
  margin-right: 0.5vw;
}

.carousel-func-desktop p {
  font-size: 0.6vw;
}

.carousel-func-mobile p {
  font-size: 2vw;
}

.carousel-content:first-of-type {
  color: white;
  margin-top: -0.5vw;
}

.carousel-img-crown-desktop {
  width: 14%;
  height: auto;
  margin-bottom: 0.5vw;
}

.carousel-img-crown-mobile {
  width: 20%;
  height: auto;
}

.info-mark-desktop {
  position: absolute;
  top: -3%;
  right: -7%;
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
  align-items: flex-end;
}

.best-seller-desktop {
  background-color: #b171b3;
  font-size: 0.6vw;
  border-radius: 2vw;
  color: white;
  width: 6.5vw;
  padding: 0.3vw 0;
}

.save-price-desktop {
  background-color: #00a0e3;
  font-size: 0.6vw;
  border-radius: 2vw;
  color: white;
  width: 5.5vw;
  padding: 0.3vw 0;
}

.info-mark-mobile {
  position: absolute;
  top: -2%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1vw;
}

.best-seller-mobile {
  background-color: #b171b3;
  font-size: 2.6vw;
  border-radius: 5vw;
  color: white;
  width: 30.5vw;
  padding: 1vw 0;
}

.save-price-mobile {
  background-color: #00a0e3;
  font-size: 2.6vw;
  border-radius: 5vw;
  color: white;
  width: 22.5vw;
  padding: 1vw 0;
}
