.aboutUs-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutUs-artwork-desktop {
  margin: 0 auto;
  width: 30%;
  position: absolute;
  top: -3vw;
  left: -25vw;
  transform: rotate(-4deg);
}

.aboutUs-artwork-mobile {
  margin: 0 auto;
  width: 60%;
  position: absolute;
  top: 0vw;
  left: -20vw;
}

.aboutUs-artwork img {
  width: 100%;
  height: auto;
}

.aboutUs-header-title {
  margin: 0 auto;
  color: #9097cb;
  text-transform: uppercase;
}

.aboutUs-header-title-mobile {
  font: normal normal normal 20vw/10.78vw MyFontPHCond;
  letter-spacing: 0px;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
  margin-top: 20vw;
}

.aboutUs-header-title-desktop {
  margin-top: 8.5vw;
  font: normal normal normal 9vw/10.78vw MyFontPHCond;
  letter-spacing: 0px;
  background-clip: text;
  position: relative;
  background-clip: text;
  z-index: 4;
}

.aboutUs-header-title-desktop::after {
  color: #9097cb;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 0.9vw #fff;
  left: 0vw;
  z-index: -3;
}

.aboutUs-header-title-mobile::after {
  color: #9097cb;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 1.2vw #fff;
  left: 0vw;
  z-index: -3;
}

.aboutUs-header-image-container-mobile {
  width: 115%;
  margin-left: -2vw;
  margin-top: -20vw;
  transform: rotate(-2deg);
}

.aboutUs-header-image-container-desktop {
  width: 115%;
  margin-left: -2vw;
  margin-top: -17vw;
  transform: rotate(-2deg);
}

.aboutUs-header-image-container img {
  width: 100%;
  height: auto;
}
