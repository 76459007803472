.android-single-wrapper-desktop {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
  gap: 5vw;
  z-index: 999;
}

.android-single-wrapper-mobile {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  align-items: center;
  gap: 5vw;
  margin-bottom: 10vw;
}

.android-single-wrapper-desktop:nth-child(even) {
  flex-direction: row-reverse;
}

.android-single-wrapper-desktop:last-child .arrow-container-desktop {
  display: none;
}

.android-single-content {
  text-align: left;
}

.android-single-container-title-desktop {
  font: normal normal normal 3.13vw/3.75vw MyFontPHCond;
}

.android-single-container-title-mobile {
  text-align: center;
  font: normal normal normal 10.13vw/3.75vw MyFontPHCond;
}

.android-single-container-title-1,
.android-single-container-title-5,
.android-single-container-title-9 {
  color: #9097cb;
}

.android-single-container-title-2,
.android-single-container-title-6 {
  color: #b4a9ee;
}

.android-single-container-title-3,
.android-single-container-title-7 {
  color: #b39ddb;
}

.android-single-container-title-4,
.android-single-container-title-8 {
  color: #cca3cd;
}

.android-single-container-description-desktop {
  color: #666666;
  font: normal normal 0.73vw/1.36vw Comfortaa;
}

.android-single-container-description-mobile {
  color: #666666;
  font: normal normal 3.3vw/5vw Comfortaa;
  margin-top: 5vw;
}

.android-single-image-container-desktop {
  width: 40%;
}

.android-single-image-container img {
  max-width: 100%;
}

.arrow-container-desktop {
  width: 13%;
  position: absolute;
  margin-top: 20vw;
}

.arrow-container-mobile {
  display: none;
}

.arrow-container img {
  max-width: 100%;
}
