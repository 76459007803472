.community-image {
  max-width: 100%;
  height: auto;
}

.community-last-container:last-of-type {
  font-weight: bold;
  
}
.community-last-container-mobile:last-of-type{
  margin-top: 4vw;
}

.community-last-container-mobile {
  text-transform: uppercase;
  font-weight: bold;
}

.community-last-container-mobile p {
  margin-bottom: 1vw;
  color: #946e95;
}