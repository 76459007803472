.ios-payment-cloud-container {
  transform: rotate(180deg);
  margin-top: 3vw;
  width: 110%;
  margin-left: -3vw;
}

.ios-payment-cloud-container img {
  width: 100%;
  height: auto;
}

.ios-payment-title-desktop {
  position: relative;
  color: #bcd99d;
  font: normal normal normal 5.73vw/7vw MyFontPHCond;
  letter-spacing: 0.88px;
  margin-top: -20vw;
}

.ios-payment-title-mobile {
  position: relative;
  color: #bcd99d;
  font: normal normal normal 10.73vw/11vw MyFontPHCond;
  letter-spacing: 0.88px;

  width: 70%;
  margin: 0 auto;
    margin-top: -5vw;
}

.ios-payment-info-desktop {
  margin: 0 auto;
  position: relative;
  width: 60%;
  color: #666666;
  font: normal normal normal 0.63vw/2vw Comfortaa;
}

.ios-payment-info-mobile {
  margin: 0 auto;
  position: relative;
  width: 90%;
  color: #666666;
  font: normal normal normal 3vw/5vw Comfortaa;
  margin-top: 3vw;
}

.ios-payment-artwork-container {
  width: 18%;
  position: absolute;
  right: 0;
  margin-top: -4vw;
}

.ios-payment-artwork-container img {
  max-width: 100%;
}
