.contacts-content-wrapper {
  background-color: white;
  margin-top: -12vw;
  padding-top: 5vw;
  padding-bottom: 10vw;
  position: relative;
}

.contacts-content-wrapper-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 17vw;
  margin-top: -14vw;
}

.contacts-content-wrapper-desktop {
  display: flex;
  flex-direction: row;
  gap: 4vw;
}

.contacts-wrapper-desktop {
  padding-bottom: 20vw;
}
