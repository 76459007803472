.icon-container {
  position: absolute;
  right: 0.3%;
  top: 10vw;
}

.icon-container-Mobile {
  width: 10%;
  height: auto;
  position: absolute;
  right: 0%;
  top: 22vw;
}

.icon-img {
  width: 70%;
  height: auto;
  filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.1));
}

.icon-img-Mobile {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.1));
}
.android-container {
  margin-bottom: 1vw;
}
