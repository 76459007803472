.App {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.main-desktop {
  flex: 1;
  display: flex;
  background-color: white;
}

.main-mobile {
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: white;
}

.error-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
  width: 10%;
}
